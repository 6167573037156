import React from "react"

import { Page } from "../components/layout"
import SEO from "../components/seo"
import AboutPageContent from "../components/about"
import { graphql } from "gatsby"

const AboutPage = ({data}) => (
  <Page theme={Math.random() > .5 ? `silver` : 'bronze'}>
    <SEO title="Pro and Co • Architecture • Urbanisme • Alexia & Michel Protsenko"/>
    <AboutPageContent metadata={data.meta.siteMetadata} />
  </Page>
)

export default AboutPage

export const query = graphql`
query aboutMeta {
  meta : site {
    siteMetadata {
      siteUrl
      title
      description
      instagram
    }
  }
}
`